<template>
  <trade-monitor-allocations
    :request-id="requestId"
    :color="color"
    @reload-routesets="emitReloadRoutesets"
    />
</template>

<script>
export default {
  props: {
    requestId: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    emitReloadRoutesets (filteredRouteSet = null) {
      this.$emit('reload-routesets', filteredRouteSet)
    }
  }
}
</script>
